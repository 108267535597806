import { template as template_e1fe1350e01046e0bb94ba27e35a6316 } from "@ember/template-compiler";
const FKLabel = template_e1fe1350e01046e0bb94ba27e35a6316(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
