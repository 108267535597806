import { template as template_6ec3266ceb72495c984b0d4cf2c9990c } from "@ember/template-compiler";
const SidebarSectionMessage = template_6ec3266ceb72495c984b0d4cf2c9990c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
