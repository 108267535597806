import { template as template_8ef98a42fd924d999f607adbf95a9e46 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_8ef98a42fd924d999f607adbf95a9e46(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
